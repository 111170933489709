import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";

import getData from "@/__main__/get-data.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { checkCoupon } from "@/feature-wallet/api.mjs";
import type { PaymentProviderType } from "@/feature-wallet/models/payment-method-provider.mjs";
import type { SubscriptionCoupon } from "@/feature-wallet/models/subscription-coupon.mjs";
import { SubscriptionCouponModel } from "@/feature-wallet/models/subscription-coupon.mjs";
import CheckIcon from "@/inline-assets/check-small.svg";
import Ripple from "@/shared/Ripple.jsx";
import { classNames } from "@/util/class-names.mjs";
import debounce from "@/util/debounce.mjs";
import { devError } from "@/util/dev.mjs";

const Styled = {
  CodeForm: styled("section")`
    height: var(--sp-9);
    display: flex;
    align-items: center;
    gap: var(--sp-4);

    & .coupon-state {
      white-space: nowrap;
      color: var(--shade2);

      &.c-error {
        color: var(--red);
      }

      & .c-success {
        color: var(--green);
      }

      & .load-spinner {
        position: relative;
        width: var(--sp-6);
        height: var(--sp-6);
        aspect-ratio: 1;
        min-height: 0;
      }
    }

    & input {
      width: 100%;
    }
  `,
};

const COUPON_ALLOWED_CHAR = /^[A-Z_]+$/g;
export function CouponCodeForm({
  coupon,
  onChange,
  isLoading,
  disabled,
  hideLabel = false,
}) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  return showForm ? (
    <Styled.CodeForm>
      {!hideLabel && (
        <label
          id="coupon-code-lbl"
          htmlFor="coupon-code"
          className="type-form--button shade1"
        >
          {t("common:wallet.redeem", "Redeem")}
        </label>
      )}
      <input
        autoFocus
        aria-labelledby="coupon-code-lbl coupon-state"
        id="coupon-code"
        type="text"
        className="type-form--button"
        placeholder={t("common:wallet.enterCode", "Enter Code")}
        onChange={onChange}
        disabled={disabled}
      />
      <span
        id="coupon-state"
        {...classNames(
          "type-caption",
          "coupon-state",
          coupon?.error && "c-error",
        )}
      >
        <CouponState coupon={coupon} isLoading={isLoading} />
      </span>
    </Styled.CodeForm>
  ) : (
    <Button
      className="w-full"
      onClick={() => setShowForm(true)}
      After={<Ripple />}
    >
      {t("common:wallet.couponPrompt", "Have a code to redeem?")}
    </Button>
  );
}

type UseCouponCodeState =
  | (SubscriptionCoupon & { code: string; error: false })
  | { error: true }
  | null;

const CouponState = ({
  coupon,
  isLoading,
}: {
  coupon: UseCouponCodeState;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  if (isLoading) return <LoadingSpinner className="load-spinner" />;
  if (!coupon) return t("common:optional.lower", "optional");
  if (!("error" in coupon) || !coupon.error) return <CheckIcon />;
  return t("common:wallet.coupon.notFound", "invalid code");
};

export function useCouponCodeProps({
  providerType,
}: {
  providerType: PaymentProviderType;
}) {
  const couponCode = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState<UseCouponCodeState>(null);

  const debouncedChange = debounce(async (code) => {
    try {
      const bearerToken = await getBearerToken();
      const coupon = await getData(
        checkCoupon({
          couponCode: code,
          providerType,
        }),
        SubscriptionCouponModel,
        undefined,
        {
          skipSafetyCheck: true,
          headers: { Authorization: bearerToken },
        },
      );
      if (code !== couponCode.current) return; // stale

      if (!coupon || !coupon.isValid || coupon.isDeleted) {
        return setCoupon({ error: true });
      }

      setCoupon({ ...coupon, code, error: false });
    } catch (e: unknown) {
      if (code === couponCode.current) setCoupon({ error: true });

      if (
        !(e instanceof Object) ||
        !("errorCode" in e) ||
        e.errorCode !== "not_found"
      ) {
        devError("error while fetching coupon", e);
        eventBus.emit(EVENT_ERROR, {
          error: e,
          tags: ["wallet", "coupon_code_check"],
        });
      }
    }
    setIsLoading(false);
  }, 500);

  const onChange = useCallback((e) => {
    const code = e.target.value.trim();
    if (!code.length) return setCoupon(null);
    if (code.length > 25 || !COUPON_ALLOWED_CHAR.test(code))
      return setCoupon({ error: true });

    couponCode.current = code;
    setIsLoading(true);
    debouncedChange(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    coupon,
    onChange,
  };
}
