import React from "react";
import { useTranslation } from "react-i18next";
import { keyframes, styled } from "goober";
import { IndeterminateLoadingSpinner } from "clutch/src/LoadingSpinner/IndeterminateLoadingSpinner.jsx";

import CheckIcon from "@/inline-assets/check.svg";
import CloseIcon from "@/inline-assets/close.svg";

const popIn = () => keyframes`
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
`;

const Styled = {
  Submit: styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--sp-3);

    text-align: center;

    & .loading-wrapper {
      width: var(--sp-12);
      height: var(--sp-12);
    }

    & .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: var(--sp-12);
      height: var(--sp-12);

      border-radius: 50%;
      border: 2px solid var(--shade10);

      animation: ${popIn} var(--transition);

      svg {
        width: var(--sp-6);
        height: var(--sp-6);
      }
    }

    &.success {
      .icon-wrapper {
        color: var(--turq);

        border-color: var(--turq);
      }
    }

    &.error {
      .icon-wrapper {
        color: var(--red);

        border-color: var(--red);
      }
    }
  `,
};

export function FormSubmitLoading() {
  const { t } = useTranslation();

  return (
    <Styled.Submit>
      <div className="loading-wrapper">
        <IndeterminateLoadingSpinner />
      </div>

      <p className="type-form--button">
        {t("common:wallet.processing", "Processing")}
      </p>
    </Styled.Submit>
  );
}

export function FormSubmitSuccess() {
  const { t } = useTranslation();

  return (
    <Styled.Submit className="success">
      <div className="icon-wrapper">
        <CheckIcon />
      </div>

      <p className="type-form--button">
        {t("common:wallet.success", "Success")}
      </p>
    </Styled.Submit>
  );
}

export function FormSubmitError({ error }: { error: Translation | string }) {
  const { t } = useTranslation();

  return (
    <Styled.Submit className="error">
      <div className="icon-wrapper">
        <CloseIcon />
      </div>

      <p className="type-form--button">
        {typeof error === "string" ? error : t(...error)}
      </p>
    </Styled.Submit>
  );
}
