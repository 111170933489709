import React, { useCallback, useState } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { PurchaseSubscriptionDialogView } from "@/feature-subscriber/PurchaseSubscriptionDialogView.jsx";
import { AddCardDialogView } from "@/feature-wallet/AddCardDialogView.jsx";
import { AddPaymentMethodDialogView } from "@/feature-wallet/AddPaymentMethodDialogView.jsx";
import { AddPaypalDialogView } from "@/feature-wallet/AddPayPalDialogView.jsx";
import { getDefaultProviderPaymentMethods } from "@/feature-wallet/utils/default-provider-payment-method.mjs";
import { DialogWindow } from "@/shared/Dialog.jsx";
import {
  createModalNavigation,
  useModalNavigation,
} from "@/shared/ModalNavigation.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const PurchaseSubscriptionNav = createModalNavigation<{
  purchaseSubscription: void;
  purchaseSubscriptionWithAlternative: string;

  addPaymentMethod: void;
  addCard: void;
  addPayPal: void;
}>("purchase-premium");

export function PurchaseSubscriptionDialog({
  onSuccess,
  closeModal,
}: {
  onSuccess(): void;
  closeModal(): void;
}) {
  const modalRouter = useModalNavigation(
    PurchaseSubscriptionNav,
    "purchaseSubscription",
  );

  const {
    wallet: { paymentProviders },
  } = useSnapshot(readState);

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    string | null
  >(() => getDefaultProviderPaymentMethods({ paymentProviders })[0]?.id);

  const onSelectedPaymentMethod = useCallback(
    (paymentMethodId: string) => {
      setSelectedPaymentMethodId(paymentMethodId);

      modalRouter.replaceRoute("purchaseSubscription");
    },
    [modalRouter],
  );

  const onAddPaymentMethod = useCallback(() => {
    modalRouter.pushRoute("addPaymentMethod");
  }, [modalRouter]);

  const onRequestAddCard = useCallback(() => {
    modalRouter.pushRoute("addCard");
  }, [modalRouter]);

  const onRequestAddPayPal = useCallback(() => {
    modalRouter.pushRoute("addPayPal");
  }, [modalRouter]);

  const onAddedPaymentMethod = useCallback(
    (paymentMethodId: string) => {
      setSelectedPaymentMethodId(paymentMethodId);

      modalRouter.setRoute("purchaseSubscription");
    },
    [modalRouter],
  );

  const onBack = useCallback(() => {
    modalRouter.popRoute();
  }, [modalRouter]);

  return (
    <DialogWindow width={400}>
      <PurchaseSubscriptionNav.Navigator
        modalRouter={modalRouter}
        onPopScope={closeModal}
      >
        <PurchaseSubscriptionNav.Route name="purchaseSubscription">
          <PurchaseSubscriptionDialogView
            selectedPaymentMethodId={selectedPaymentMethodId}
            onSelectedPaymentMethod={onSelectedPaymentMethod}
            onAddPaymentMethod={onAddPaymentMethod}
            onSuccess={onSuccess}
          />
        </PurchaseSubscriptionNav.Route>

        <PurchaseSubscriptionNav.Route name="addPaymentMethod">
          <AddPaymentMethodDialogView
            onRequestAddCard={onRequestAddCard}
            onRequestAddPayPal={onRequestAddPayPal}
            onPressBack={onBack}
          />
        </PurchaseSubscriptionNav.Route>

        <PurchaseSubscriptionNav.Route name="addCard">
          <AddCardDialogView
            onAddedPaymentMethod={onAddedPaymentMethod}
            onBack={onBack}
          />
        </PurchaseSubscriptionNav.Route>

        <PurchaseSubscriptionNav.Route name="addPayPal">
          <AddPaypalDialogView
            onAddedPaymentMethod={onAddedPaymentMethod}
            onBack={onBack}
          />
        </PurchaseSubscriptionNav.Route>
      </PurchaseSubscriptionNav.Navigator>
    </DialogWindow>
  );
}
