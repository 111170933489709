import React from "react";
import { styled } from "goober";

import LoadingSpinnerIcon from "../Icons/indeterminate-loading-spinner.svg";

const Container = styled("span")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  aspect-ratio: 1;

  color: var(--spinner-color, inherit);

  svg {
    width: 100%;
    height: 100%;
  }
`;

export function IndeterminateLoadingSpinner({
  alert = true,
  ...rest
}: React.HTMLAttributes<HTMLSpanElement> & {
  alert?: boolean;
}) {
  return (
    <Container
      className="loading-spinner"
      {...(alert && { role: "alert", "aria-busy": "true" })}
      {...rest}
    >
      <LoadingSpinnerIcon />
    </Container>
  );
}
