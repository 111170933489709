import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { postData } from "@/__main__/get-data.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_ERROR } from "@/app/ErrorBoundary.jsx";
import { updateUserRoles } from "@/feature-auth/utils/auth-actions.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { createSubscription } from "@/feature-subscriber/api.mjs";
import { SUBSCRIBER_PAYMENT_PLAN } from "@/feature-subscriber/constants.mjs";
import { EVENT_PRO_CONVERSION } from "@/feature-subscriber/constants/events.mjs";
import { CreateProSubscriptionModel } from "@/feature-subscriber/model-create-pro-subscription.mjs";
import { PaymentMethodDropdown } from "@/feature-subscriber/PaymentMethodDropdown.jsx";
import { isUserSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";
import { createOrRenewSubscription } from "@/feature-wallet/actions.mjs";
import {
  CouponCodeForm,
  useCouponCodeProps,
} from "@/feature-wallet/CouponCodeForm.jsx";
import {
  FormSubmitError,
  FormSubmitLoading,
  FormSubmitSuccess,
} from "@/feature-wallet/FormSubmitSpinner.jsx";
import { getPaymentMethod } from "@/feature-wallet/utils/payment-methods.mjs";
import { useTrialEndDate } from "@/feature-wallet/utils/payment-plans.mjs";
import { DialogBody, DialogContent, DialogHeader } from "@/shared/Dialog.jsx";
import { GraphQLError } from "@/util/custom-errors.mjs";
import { devError } from "@/util/dev.mjs";
import { formatCurrency } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/proxy.mjs";

export function PurchaseSubscriptionDialogView({
  selectedPaymentMethodId,
  onSelectedPaymentMethod,
  onAddPaymentMethod,
  onSuccess,
}: {
  selectedPaymentMethodId?: string | null;
  onAddPaymentMethod: () => void;
  onSelectedPaymentMethod(paymentMethodId: string): void;
  onSuccess(): void;
}) {
  const {
    subscriber: { pricing },
    wallet: { paymentProviders },
  } = useSnapshot(readState);

  const trialEndDate = useTrialEndDate({
    paymentPlan: pricing,
    startDate: new Date(),
  });

  const renewalDate = useMemo(() => {
    if (!pricing) return null;

    const subscriptionStartDate = new Date();

    if (pricing?.isEligibleForFreeTrial) {
      return trialEndDate;
    }

    return (
      SUBSCRIBER_PAYMENT_PLAN[pricing.recurringInterval]?.getRenewalDateFrom(
        subscriptionStartDate,
      ) ?? null
    );
  }, [trialEndDate, pricing]);

  const canSubmit = Boolean(selectedPaymentMethodId);

  const paymentMethod = getPaymentMethod({
    paymentProviders,
    paymentMethodId: selectedPaymentMethodId,
  });

  const {
    coupon,
    isLoading: checkingCoupon,
    onChange,
  } = useCouponCodeProps({
    providerType: paymentMethod?.providerType ?? "STRIPE",
  });

  const [submitState, setSubmitState] = useState<
    | { status: "idle" }
    | { status: "submitting" }
    | { status: "success" }
    | { status: "error"; error: Translation | string }
  >({ status: "idle" });

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (!canSubmit) return;

      if (!pricing) return;

      setSubmitState({ status: "submitting" });

      try {
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });

        const bearerToken = await getBearerToken();

        const couponCode =
          coupon && coupon.error !== true ? coupon.code : undefined;

        if (!(pricing.recurringInterval in SUBSCRIBER_PAYMENT_PLAN)) {
          throw new Error(
            `No subscription plan for ${pricing.recurringInterval}`,
          );
        }

        const subscription = await postData(
          createSubscription({
            couponCode,
            isTrial: !couponCode && pricing.isEligibleForFreeTrial,
            plan: SUBSCRIBER_PAYMENT_PLAN[pricing.recurringInterval].planId,
            paymentMethodId: paymentMethod.id,
            providerType: paymentMethod.providerType,
          }),
          CreateProSubscriptionModel,
          undefined,
          { headers: { Authorization: bearerToken } },
        );

        if (subscription instanceof Error) throw subscription;

        createOrRenewSubscription(subscription);

        eventBus.emit(EVENT_PRO_CONVERSION, {
          providerType: paymentMethod.providerType,
        });

        await retryGetUserRoles();

        setSubmitState({ status: "success" });
      } catch (e) {
        // TODO: better error communication
        devError("Error creating premium subscription", e);

        setSubmitState({
          status: "error",
          error:
            e instanceof GraphQLError
              ? e.errorMessage === "user is not set to be canceled"
                ? [
                    "subscriber:purchase.error.alreadyActive",
                    "Subscription already active.",
                  ]
                : e.errorMessage
              : e instanceof Error
                ? e.message
                : `${e}`,
        });

        eventBus.emit(EVENT_ERROR, {
          error: e,
          tags: ["subscription", "subscription_subscribe"],
        });
      }
    },
    [canSubmit, paymentMethod, coupon, pricing],
  );

  useEffect(() => {
    if (submitState.status !== "success") return;

    const timeoutId = setTimeout(onSuccess, 2000);

    return () => clearTimeout(timeoutId);
  }, [submitState.status, onSuccess]);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <DialogContent>
      <DialogHeader
        title={["common:subscriber.purchaseModal.title", "Purchase Premium"]}
      />

      <Form onSubmit={onSubmit}>
        <DialogBody>
          <div className="select-plan">
            {/* <h2 className="type-subtitle--bold">
                {t("common:subscriber.purchaseModal.selectPlan", "Select Plan")}
              </h2> */}

            <ul>
              <li>
                <input
                  type="radio"
                  name="radio"
                  value={0}
                  checked
                  disabled={submitState.status === "submitting"}
                />

                <label htmlFor={`package-0`} className="type-subtitle--semi">
                  <div className="duration">
                    {t("common:subscriber.purchaseModal.1month", "1 Month")}
                  </div>
                  <div className="price">
                    {pricing &&
                      formatCurrency(
                        language,
                        pricing.price / 100,
                        pricing.currency,
                      )}
                  </div>
                </label>
              </li>
            </ul>
          </div>

          <hr />

          <div>
            <div className="line-item select-payment-method">
              <div className="type-subtitle--semi">
                {t("common:subscriber.purchaseModal.payment", "Payment")}
              </div>
              <div className="flex justify-end">
                <PaymentMethodDropdown
                  selectedPaymentMethodId={selectedPaymentMethodId}
                  onSelectedPaymentMethod={onSelectedPaymentMethod}
                  onAddPaymentMethod={onAddPaymentMethod}
                />
              </div>
            </div>

            <hr />

            <div className="policy">
              <div className="line-item">
                <div className="type-subtitle--semi">
                  {t("common:wallet.policy", "Policy")}
                </div>
                <div className="type-caption">
                  {t(
                    "common:subscriber.purchaseModal.policy",
                    "Monthly recurring charge, starting {{currentDate}}. Your subscription will renew for {{price}} on {{renewalDate}}. Cancel anytime on your Subscriptions settings page.",
                    {
                      currentDate: new Date().toLocaleDateString(language, {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }),
                      renewalDate: renewalDate?.toLocaleDateString(language, {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }),
                      price:
                        pricing &&
                        formatCurrency(
                          language,
                          pricing.price / 100,
                          pricing.currency,
                        ),
                    },
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="total">
            <hr />

            <div className="line-item">
              <div className="type-subtitle--semi">
                {t("common:wallet.total", "Total")}
              </div>
              <div className="type-title--semi">
                {pricing &&
                  formatCurrency(
                    language,
                    pricing.price / 100,
                    pricing.currency,
                  )}
              </div>
            </div>
          </div>

          <div className="redeem-coupon">
            <div className="line-item">
              <div className="type-subtitle--semi">
                {t("common:wallet.redeem", "Redeem")}
              </div>
              <div>
                {/* TODO: this is not the correct style */}
                <CouponCodeForm
                  coupon={coupon}
                  onChange={onChange}
                  isLoading={checkingCoupon}
                  disabled={submitState.status === "submitting"}
                  hideLabel
                />
              </div>
            </div>
          </div>

          <div className="submit-wrapper">
            {submitState.status === "submitting" ? (
              <FormSubmitContainer>
                <FormSubmitLoading />
              </FormSubmitContainer>
            ) : submitState.status === "error" ? (
              <FormSubmitContainer>
                <FormSubmitError error={submitState.error} />

                <Button
                  emphasis="high"
                  size="medium"
                  type="submit"
                  disabled={!canSubmit}
                  onClick={onSubmit}
                >
                  {t("common:wallet.tryAgain", "Try Again")}
                </Button>
              </FormSubmitContainer>
            ) : submitState.status === "success" ? (
              <FormSubmitContainer>
                <FormSubmitSuccess />
              </FormSubmitContainer>
            ) : (
              <Button
                type="submit"
                emphasis="high"
                block
                disabled={!canSubmit}
                onClick={onSubmit}
              >
                {t("common:subscribe", "Subscribe")}
              </Button>
            )}
          </div>
        </DialogBody>
      </Form>
    </DialogContent>
  );
}

async function retryGetUserRoles() {
  for (let retries = 3; retries > 0; retries--) {
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    await updateUserRoles();

    const isSubscriber = isUserSubscriber(readState.user);

    if (isSubscriber) {
      return true;
    }
  }

  throw new Error(
    "Role was not applied after subscription creation. User may not have access to premium features.",
  );
}

const Form = styled("form")`
  hr {
    width: 100%;
    height: var(--sp-px);
    margin: 0;

    border: none;
    border-top: var(--sp-px) solid var(--shade3-15);

    outline: none;
  }

  button[data-emphasis="high"] {
    & {
      --bg: var(--shade0) !important;
      --border: transparent !important;
      color: var(--shade7) !important;
      background-image: none !important;
    }

    &:hover,
    &:focus {
      --bg: hsla(0deg 0% 100% / 0.85) !important;
    }
  }

  .line-item {
    display: flex;
    justify-content: space-between;
    gap: var(--sp-4);

    & > *:first-child {
      color: var(--shade2);
    }

    & > *:nth-child(2) {
      color: var(--shade0);
    }
  }

  .select-plan {
    margin-bottom: var(--sp-6);

    .line-item {
      align-items: center;

      padding-block: var(--sp-3);
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: var(--sp-4);

      li {
        margin-left: var(--sp-1_5);
      }
    }

    label {
      display: flex;
      align-items: center;
      gap: var(--sp-4);
    }

    input[type="radio"] {
      position: absolute;

      opacity: 0;

      + label {
        color: var(--shade1);

        opacity: 0.75;

        &:before {
          --border-color: var(--shade3);
          --dot-color: transparent;

          content: "";
          position: relative;

          display: inline-block;
          width: var(--sp-2_5);
          height: var(--sp-2_5);

          border-radius: 100%;
          border: var(--sp-0_5) solid transparent;
          background-color: var(--dot-color);
          background-clip: padding-box;

          outline: var(--sp-0_5) solid var(--border-color);

          cursor: pointer;

          transition: var(--transition-long);
          transition-property: background-color, outline;
        }
      }

      &:checked,
      &:focus {
        + label {
          color: var(--shade0);

          opacity: 1;

          &:before {
            --border-color: var(--subscriber-solid);
            --dot-color: var(--subscriber-solid);
          }
        }
      }

      &:checked:disabled {
        + label {
          color: var(--shade1);

          opacity: 0.75;

          &:before {
            --border-color: hsla(var(--subscriber-solid-hsl) / 0.5);
            --dot-color: hsla(var(--subscriber-solid-hsl) / 0.5);
          }
        }
      }
    }

    .duration {
      flex: 1;

      .savings {
        margin-left: var(--sp-2);

        color: var(--subscriber-solid);
      }
    }

    .price {
      .per-month {
        margin-right: var(--sp-2);

        color: var(--shade1);
      }
    }
  }

  .select-payment-method {
    align-items: center;

    padding-block: var(--sp-1);
  }

  .policy {
    .line-item {
      padding-block: var(--sp-3);

      & > *:nth-child(1) {
        flex: 1;
      }

      & > *:nth-child(2) {
        flex: 4;

        color: var(--shade1-75);
      }
    }
  }

  .total {
    margin-top: var(--sp-6);
    margin-bottom: var(--sp-3);

    .line-item {
      align-items: center;

      padding-block: var(--sp-3);

      & > *:first-child {
        color: var(--shade0-75);
      }

      & > *:nth-child(2) {
        text-align: right;
      }
    }
  }

  .redeem-coupon {
    margin-bottom: var(--sp-3);

    .line-item {
      align-items: center;
    }
  }

  .submit-wrapper {
    margin-top: var(--sp-6);
  }
`;

const FormSubmitContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;

  button {
    margin-top: var(--sp-6);
  }
`;
