import React, { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import router, { setRoute } from "@/__main__/router.mjs";
import {
  GAME_BOX_ICONS,
  GAME_COLORS,
  GAME_PREFERRED_NAME_MAP,
} from "@/app/constants.mjs";
import type { GameSymbol } from "@/app/games.mjs";
import { getRedirectUrl } from "@/app/util.mjs";
import {
  Header,
  PageWrapper,
  PlanComparison,
  Plans,
  SubscriberText,
} from "@/feature-subscriber/Landing.style.jsx";
import { PurchaseSubscriptionDialog } from "@/feature-subscriber/PurchaseSubscriptionDialog.jsx";
import { SUBSCRIPTION_TRIAL_DAYS } from "@/feature-wallet/utils/payment-plans.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import ArrowRightIcon from "@/inline-assets/arrow-right2.svg";
import CheckIcon from "@/inline-assets/check.svg";
import CloseIcon from "@/inline-assets/close.svg";
import SubscriberIcon from "@/inline-assets/pro.svg";
import SubscriberSparklesIcon from "@/inline-assets/pro-sparkles.svg";
import FullscreenOverlay from "@/shared/FullscreenOverlay.jsx";
import { classNames } from "@/util/class-names.mjs";
import { formatCurrency } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/proxy.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

interface Feature {
  name: Translation;
  basic: boolean | "limited";
}

interface GameFeaturesSection {
  gameSymbol: GameSymbol;
  features: Feature[];
}

const GENERAL_FEATURES: Feature[] = [
  {
    name: [
      "common:subscriber.compareLolFeatures.subscriber.adFreeExperience",
      "Ad-free experience",
    ],
    basic: false,
  },
];

const GAME_FEATURE_SECTIONS: GameFeaturesSection[] = [
  {
    gameSymbol: GAME_SYMBOL_LOL,
    features: [
      {
        name: [
          "common:subscriber.compareLolFeatures.subscriber.mastersPlusBuilds",
          "Masters+ Builds",
        ],
        basic: false,
      },
      {
        name: [
          "common:subscriber.compareLolFeatures.subscriber.customizableCsOverlay",
          "Customizable CS Overlay",
        ],
        basic: false,
      },
      {
        name: [
          "common:subscriber.compareLolFeatures.subscriber.supportOverlay",
          "Support Item Overlay",
        ],
        basic: false,
      },
      {
        name: [
          "common:subscriber.compareLolFeatures.subscriber.supportPostMatchReports",
          "Support Item Postmatch Report",
        ],
        basic: false,
      },
    ],
  },
];

function LandingPage() {
  const {
    subscriber: { pricing },
  } = useSnapshot(readState);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openPurchaseModal = useCallback(() => {
    if (!readState.user) {
      setRoute("/account", undefined, { returnTo: "/premium" });

      return;
    }

    setIsModalOpen(true);
  }, []);

  const closePurchaseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const route = useRoute<{
    returnTo?: string;
  }>();

  const returnTo = useMemo(() => {
    return (
      (route.searchParams.get("returnTo") as string) ??
      route.state.returnTo ??
      router.previousRoute?.currentPath ??
      getRedirectUrl(readState.settings, readState.localState) ??
      "/"
    );
  }, [route.searchParams, route.state.returnTo]);

  const onSuccess = useCallback(() => {
    setRoute(returnTo, undefined, undefined, true);
  }, [returnTo]);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <PageWrapper>
      <Header>
        <h1 className="type-page-header">
          <Trans i18nKey="common:subscriber.landingPage.header.cta">
            Get more from Blitz with <SubscriberIcon />
            <SubscriberText>Premium</SubscriberText>
          </Trans>
        </h1>

        <Plans>
          <div className="plan highlight">
            <div className="border" />

            <div className="content">
              {pricing?.isEligibleForFreeTrial && (
                <div className="overline type-callout--bold">
                  <SubscriberSparklesIcon className="original-colors" />
                  <SubscriberText>
                    {t(
                      "common:subscriber.paymentBillingPage.trialDaysFree",
                      "{{count}} days free",
                      {
                        count: SUBSCRIPTION_TRIAL_DAYS,
                      },
                    )}
                  </SubscriberText>
                </div>
              )}

              <div className="title type-title--bold">
                {t(
                  "common:subscriber.landingPage.planMonthly.title",
                  "Premium Monthly",
                )}
              </div>
              <div className="pricing">
                <Trans i18nKey="common:subscriber.landingPage.planMonthly.pricing">
                  <span className="type-caption">from</span>
                  <div className="price-block type-caption">
                    <SubscriberText className="price type-page-header">
                      {pricing &&
                        formatCurrency(
                          language,
                          pricing.price / 100,
                          pricing.currency,
                        )}
                    </SubscriberText>
                    <span className="type-title--semi">/month</span>
                  </div>
                  <span className="type-caption">Billed monthly</span>
                </Trans>
              </div>
              <div className="actions">
                <Button
                  emphasis="high"
                  block
                  iconRight={<ArrowRightIcon />}
                  onClick={openPurchaseModal}
                >
                  {t("common:subscriber.getSubscriber", "Get Premium")}
                </Button>
              </div>
            </div>
          </div>
        </Plans>

        <p className="agreement type-caption">
          <Trans i18nKey="common:subscriber.landingPage.signUpAgreement">
            By signing up, you have read and agree to our{" "}
            <a href="/legal/terms-of-service" target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/legal/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            .
          </Trans>
        </p>
      </Header>

      <hr />

      <PlanComparison>
        <h2 className="type-large-title--bold">
          {t(
            "common:subscriber.landingPage.planComparison.title",
            "Pick your Plan",
          )}
        </h2>

        <header>
          <div className="col">
            <div className="title type-subtitle--bold">
              {t(
                "common:subscriber.landingPage.planComparison.general",
                "General Features",
              )}
            </div>
          </div>

          <div className="col">
            <div className="title type-title--bold">
              {t(
                "common:subscriber.landingPage.planComparison.basic.title",
                "Basic",
              )}
            </div>
            <div className="subtitle type-callout--semi">
              {t(
                "common:subscriber.landingPage.planComparison.basic.subtitle",
                "Free",
              )}
            </div>
          </div>

          <div className="col">
            <div className="title type-title--bold">
              <SubscriberIcon />
              <SubscriberText>
                {t(
                  "common:subscriber.landingPage.planComparison.subscriber.title",
                  "Premium",
                )}
              </SubscriberText>
            </div>
            <div className="subtitle type-callout--semi">
              {pricing &&
                t(
                  "common:subscriber.landingPage.planComparison.subscriber.subtitle",
                  "from {{ price, currency }}/month",
                  {
                    price: pricing.price / 100,
                    formatParams: { currency: pricing.currency },
                  },
                )}
            </div>
          </div>
        </header>

        <div className="features-block">
          <div className="pro-gradient" />

          <section>
            <div className="body type-callout">
              {GENERAL_FEATURES.map(({ name, basic }) => (
                <div key={name[0].toString()} className="row">
                  <div className="col">{t(...name)}</div>

                  <div {...classNames("col", basic === true && "enabled")}>
                    {basic === true ? (
                      <CheckIcon />
                    ) : basic === "limited" ? (
                      "Limited"
                    ) : (
                      <CloseIcon />
                    )}
                  </div>

                  <div className="col enabled">
                    <CheckIcon />
                  </div>
                </div>
              ))}
            </div>
          </section>

          {GAME_FEATURE_SECTIONS.map(({ gameSymbol, features }) => {
            const GameIcon = GAME_BOX_ICONS[gameSymbol];

            return (
              <section
                key={gameSymbol.toString()}
                style={{ "--game-accent-color": GAME_COLORS[gameSymbol] }}
              >
                <header>
                  <div className="col">
                    <div className="title type-subtitle--bold">
                      <GameIcon className="icon" />

                      {t(...GAME_PREFERRED_NAME_MAP[gameSymbol])}
                    </div>
                  </div>
                  <div className="col"></div>
                </header>

                <div className="body type-callout">
                  {features.map(({ name, basic }) => (
                    <div key={name[0].toString()} className="row">
                      <div className="col">{t(...name)}</div>

                      <div {...classNames("col", basic === true && "enabled")}>
                        {basic === true ? (
                          <CheckIcon />
                        ) : basic === "limited" ? (
                          "Limited"
                        ) : (
                          <CloseIcon />
                        )}
                      </div>

                      <div className="col enabled">
                        <CheckIcon />
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            );
          })}
        </div>
      </PlanComparison>

      <FullscreenOverlay isOpen={isModalOpen} onModalClose={closePurchaseModal}>
        <PurchaseSubscriptionDialog
          onSuccess={onSuccess}
          closeModal={closePurchaseModal}
        />
      </FullscreenOverlay>
    </PageWrapper>
  );
}

export function meta() {
  return {
    title: ["home:meta.subscriber.title", "Blitz.gg - Premium"],
    description: [
      "home:meta.subscriber.description",
      "Unlock more features and an ad-free experience. Cancel anytime. Get 7 days free when you sign up for Premium!",
    ],
  };
}

export default LandingPage;
