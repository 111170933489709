import { useMemo } from "react";
import type { DeepReadonly } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import type { PaymentMethod } from "@/feature-wallet/models/payment-method.mjs";
import type { PaymentProviderType } from "@/feature-wallet/models/payment-method-provider.mjs";
import type { UserPaymentProvider } from "@/feature-wallet/models/user-payment-methods.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export function getDefaultProviderPaymentMethod(
  paymentProvider: DeepReadonly<UserPaymentProvider> | undefined,
): PaymentMethod | null {
  if (!paymentProvider) {
    return null;
  }

  const searchForPaymentMethodId = paymentProvider.defaultPaymentMethodId;

  if (paymentProvider.defaultPaymentMethodId) {
    for (const paymentMethod of paymentProvider.paymentMethods) {
      if (paymentMethod.id === searchForPaymentMethodId) {
        return paymentMethod;
      }
    }
  }

  // Use the first payment method a default is not set (or found)
  return paymentProvider.paymentMethods[0] ?? null;
}

export function getDefaultProviderPaymentMethods({
  paymentProviders,
}: {
  paymentProviders: DeepReadonly<UserPaymentProvider[]>;
}): ReadonlyArray<PaymentMethod> {
  const defaultPaymentMethods: PaymentMethod[] = [];

  for (const paymentProvider of paymentProviders) {
    const defaultPaymentMethod =
      getDefaultProviderPaymentMethod(paymentProvider);

    if (defaultPaymentMethod === null) {
      continue;
    }

    defaultPaymentMethods.push(defaultPaymentMethod);
  }

  return defaultPaymentMethods;
}

export function useDefaultProviderPaymentMethod(
  type: PaymentProviderType,
): PaymentMethod {
  const { paymentProviders } = useSnapshot(readState.wallet);

  return useMemo(() => {
    const paymentProvider = paymentProviders[type];

    if (paymentProvider === undefined) {
      return null;
    }

    return getDefaultProviderPaymentMethod(paymentProvider);
  }, [paymentProviders, type]);
}

export function useDefaultProviderPaymentMethods(): ReadonlyArray<PaymentMethod> {
  const { paymentProviders } = useSnapshot(readState.wallet);

  return useMemo(
    () => getDefaultProviderPaymentMethods({ paymentProviders }),
    [paymentProviders],
  );
}
