import { styled } from "goober";

export const PageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);

  width: 100%;
  max-width: 704px;
  padding-inline: var(--sp-4);

  hr {
    width: 100%;
    height: var(--sp-px);
    margin: 0;

    background: var(--shade3-15);
    border: none;
  }

  button[data-emphasis="high"] {
    & {
      --bg: var(--shade0) !important;
      --border: transparent !important;
      color: var(--shade7) !important;
    }

    &:hover,
    &:focus {
      --bg: hsla(0deg 0% 100% / 0.85) !important;
    }
  }

  button.emphasize-hover {
    &:hover,
    &:focus {
      --bg: var(--shade0) !important;
      --border: transparent !important;
      color: var(--shade7) !important;
    }
  }
`;

export const Header = styled("header")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-3);

  & > h1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--sp-2);

    margin-block: var(--sp-5);

    svg {
      width: var(--sp-8);
      height: var(--sp-8);
    }
  }

  .agreement {
    margin-block: var(--sp-2) var(--sp-1);

    color: var(--shade3);

    text-align: center;

    a {
      text-decoration: underline;
    }
  }
`;

export const SubscriberText = styled("span")`
  background: var(--subscriber-gradient);
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Plans = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: var(--sp-1_5);

  .plan {
    flex: 1;

    position: relative;

    min-width: 250px;
    border-radius: var(--sp-2);

    overflow: hidden;

    .border {
      position: absolute;
      inset: 0;

      pointer-events: none;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: var(--sp-2);

      padding: var(--sp-4);

      .overline {
        display: flex;
        gap: var(--sp-1);

        svg {
          width: var(--sp-4_5);
          height: var(--sp-4_5);

          &:not(.original-colors) path {
            fill: currentColor;
          }
        }
      }

      .pricing {
        display: flex;
        flex-direction: column;

        margin-block: var(--sp-2) var(--sp-4_5);

        color: var(--shade0-50);

        .price-block {
          display: flex;
          align-items: end;
          gap: var(--sp-1);

          .price {
            display: flex;
            align-items: center;
            gap: var(--sp-1_5);

            height: 2.25rem;

            color: var(--shade0);

            img,
            svg {
              width: auto;
              height: var(--sp-5);
            }
          }

          .period {
            color: var(--shade1);
          }
        }
      }

      .actions {
        display: flex;
        gap: var(--sp-2);
      }
    }

    &.highlight {
      background: var(--subscriber-gradient-10);

      .border {
        &::before {
          content: "";

          position: absolute;
          inset: 0;

          border: var(--sp-0_5) solid transparent;
          border-radius: var(--sp-2);

          background: var(--subscriber-gradient-50) border-box;
          -webkit-mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
          -webkit-mask-composite: destination-out;
          mask-composite: exclude;
        }
      }
    }

    &.bolts {
      background: linear-gradient(0deg, var(--shade8), var(--shade8)),
        linear-gradient(0deg, var(--shade3-15), var(--shade3-15));

      .border {
        border: var(--sp-0_5) solid var(--shade3-15);
      }
    }
  }
`;

export const PlanComparison = styled("div")`
  margin-top: var(--sp-6);

  header,
  .row {
    display: flex;
  }

  .col {
    flex: 1;

    display: flex;
    flex-direction: column;

    overflow-wrap: anywhere;
  }

  header {
    .col {
      justify-content: end;
      gap: var(--sp-1);

      padding-bottom: var(--sp-3);

      &:not(:first-child) {
        align-items: center;
        text-align: center;
      }
    }

    .title {
      display: flex;
      align-items: center;
      gap: var(--sp-3);

      color: var(--shade0);
    }

    .subtitle {
      color: var(--shade1);
    }
  }

  .features-block {
    position: relative;

    .pro-gradient {
      position: absolute;
      inset: 0;
      left: unset;

      width: calc(33.33% + var(--sp-1));
      height: 100%;

      background: var(--subscriber-gradient-10);
      border: var(--sp-0_5) solid hsla(var(--subscriber-solid-hsl) / 0.5);
    }

    section {
      header {
        position: sticky;
        top: 0;

        .col {
          padding-top: var(--sp-4);

          border-top: var(--sp-px) solid var(--shade3-15);

          &:first-child {
            background-color: var(--app-bg);
          }
        }

        .title {
          display: flex;
          gap: var(--sp-2);

          color: var(--shade0);

          .icon {
            width: var(--sp-5);
            height: var(--sp-5);

            background: var(--game-accent-color);
            border-radius: 5px;

            path {
              fill: var(--shade9);
            }
          }
        }

        .subtitle {
          color: var(--shade1);
        }
      }

      .col:not(:first-child) {
        align-items: center;
      }

      .body {
        svg {
          width: var(--sp-6);
          height: var(--sp-6);
        }

        .row {
          .col {
            padding: var(--sp-3_5) var(--sp-4);

            border-top: var(--sp-px) solid var(--shade3-15);
          }

          .col:first-child {
            color: var(--shade0-75);
          }

          .col:nth-child(2) {
            color: var(--shade3);

            &.enabled {
              color: var(--shade0);
            }
          }
        }

        .col:nth-child(3) {
          color: var(--subscriber-solid);
        }
      }
    }
  }
`;
