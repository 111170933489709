import { useMemo } from "react";

import type { PaymentPlan } from "@/feature-wallet/models/payment-plans.mjs";

export const SUBSCRIPTION_TRIAL_DAYS = 30;

export function getTrialEndDate(params: {
  paymentPlan: PaymentPlan;
  startDate: Date;
}): Date;

export function getTrialEndDate(params: {
  paymentPlan: PaymentPlan | null;
  startDate: Date;
}): Date | null;

export function getTrialEndDate({
  paymentPlan,
  startDate,
}: {
  paymentPlan: PaymentPlan | null;
  startDate: Date;
}): Date | null {
  if (!paymentPlan) {
    return null;
  }

  const endDate = new Date(startDate);

  endDate.setDate(endDate.getDate() + SUBSCRIPTION_TRIAL_DAYS);

  return endDate;
}

export function useTrialEndDate(params: {
  paymentPlan: PaymentPlan;
  startDate: Date;
}): Date;

export function useTrialEndDate(params: {
  paymentPlan: PaymentPlan | null;
  startDate: Date;
}): Date | null;

export function useTrialEndDate({
  paymentPlan,
  startDate,
}: {
  paymentPlan: PaymentPlan;
  startDate: Date;
}): Date | null {
  return useMemo(
    () => getTrialEndDate({ startDate, paymentPlan }),
    [paymentPlan, startDate],
  );
}
